<template>
  <div class="scale-box">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    width: {
      type: String,
      default: 1920
    },
    height: {
      type: String,
      default: 1080
    }
  },
  data() {
    return {
      scale: 1
    };
  },
  methods: {
    init() {
      this.setScale();
      window.addEventListener('resize', this.setScale);
    },

    setScale() {
      let ww = document.documentElement.clientWidth / this.width;
      let wh = document.documentElement.clientHeight / this.height;
      this.scale = ww < wh ? ww : wh;
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.cloaEventListener('resize', this.setScale);
  }
};
</script>

<style scoped>
.scale-box {
  width: 1920px;
  height: 1080px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: scale(v-bind(scale)) translate(-50%, -50%);
  transform-origin: 0px 0px;
  transition: 0.3s;
}
</style>
