<template>
  <div class="LeftOne">
    <div class="title_box">
      <div class="title_class">气瓶库存</div>
      <div class="numAll">总计: 1200个</div>
    </div>
    <div class="chart_box" ref="line_chart"></div>
    <select v-model="selectVal" class="selectBox" @change="handleChangeSelect">
      <option :value="1">天安店</option>
      <option :value="2">地安店</option>
    </select>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  props: {
    msg: String
  },
  computed: {
    totalPage() {
      return this.dataList.length % this.currentSize === 0
        ? this.dataList.length / this.currentSize
        : this.dataList.length / this.currentSize + 1;
    }
  },
  data() {
    return {
      selectVal: 1,
      chartInstance: null,
      timerId: null,
      currentPage: 1,
      currentSize: 5,
      dataList: [
        {
          name: '送装',
          value: 100
        },
        {
          name: '冲撞',
          value: 300
        },
        {
          name: '返程',
          value: 200
        },
        {
          name: '送装',
          value: 100
        },
        {
          name: '冲撞',
          value: 300
        },

        {
          name: '送装1',
          value: 50
        },
        {
          name: '冲撞1',
          value: 80
        },
        {
          name: '送装',
          value: 30
        },
        {
          name: '冲撞',
          value: 130
        },
        {
          name: '返程',
          value: 100
        }
      ],
      option: {
        title: {
          show: false,
          text: '折线图'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          show: false,
          data: []
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '8%',
          top: '22%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: [],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              // 坐标轴刻度标签相关配置
              color: '#ffffff',
              fontSize: 14
            },
            splitLine: {
              // 坐标轴在 grid 区域中的分隔线
              lineStyle: {
                // 分割线配置
                color: 'rgba(255,255,255,0.15)' // 分割线颜色
              }
            }
          }
        ],
        series: [
          {
            type: 'line',
            stack: 'Total',
            label: {
              show: true,
              position: 'top'
            },
            emphasis: {
              focus: 'series'
            },
            lineStyle: {
              width: 3
            },
            smooth: false,
            symbolSize: 6,
            // areaStyle: {},
            itemStyle: {
              borderWidth: 5,
              // 指明颜色渐变的方向
              // 指明不同百分比之下颜色的值
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                // 百分之0状态之下的颜色值
                {
                  offset: 0,
                  color: 'rgba(44, 110, 255, 0.5)'
                },
                // 百分之100状态之下的颜色值
                {
                  offset: 1,
                  color: '#1F9BFF'
                }
              ])
            },

            data: []
          },
          {
            type: 'line',
            stack: 'Total',
            label: {
              show: true,
              position: 'top'
            },
            emphasis: {
              focus: 'series'
            },
            lineStyle: {
              width: 3
            },
            smooth: false,
            symbolSize: 6,
            // areaStyle: {},
            itemStyle: {
              borderWidth: 5,
              // 指明颜色渐变的方向
              // 指明不同百分比之下颜色的值
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                // 百分之0状态之下的颜色值
                {
                  offset: 0,
                  color: 'rgba(43, 110, 255, 0.5)'
                },
                // 百分之100状态之下的颜色值
                {
                  offset: 1,
                  color: '#1F9BFF'
                }
              ])
            },

            data: []
          }
        ]
      }
    };
  },

  methods: {
    initChart() {
      this.chartInstance = echarts.init(this.$refs.line_chart);
      this.chartInstance.setOption(this.option);
      // this.chartInstance.on('mouseover', () => {
      //   clearInterval(this.timerId);
      // });
      // this.chartInstance.on('mouseout', () => {
      //   this.startInterval();
      // });
    },
    // 更新图表
    updateChart() {
      const start = (this.currentPage - 1) * this.currentSize;
      const end = this.currentPage * this.currentSize;

      const showData = this.dataList.slice(start, end);
      const dataNames = showData.map((item) => {
        return item.name;
      });

      const dataValues = showData.map((item) => {
        return item.value;
      });

      this.option.xAxis[0].data = dataNames;
      this.option.series[0].data = dataValues;
      this.option.series[1].data = dataValues;
      this.chartInstance.setOption(this.option);
    },
    startInterval() {
      if (this.timerId) {
        clearInterval(this.timerId);
      }
      this.timerId = setInterval(() => {
        this.currentPage++;
        if (this.currentPage > this.totalPage) {
          this.currentPage = 1;
        }
        this.updateChart();
      }, 3000);
    },
    handleChangeSelect(val) {
      if (val == this.selectVal) return;
      this.currentPage++;
      if (this.currentPage > this.totalPage) {
        this.currentPage = 1;
      }
      this.updateChart();
    }
  },

  mounted() {
    this.initChart();
    this.updateChart();
    // this.startInterval();
  }
};
</script>

<style lang="scss" scoped>
.LeftOne {
  // background: url('../assets/leftTwo.png');
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  position: relative;
  .title_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(
      to right,
      rgba(59, 247, 240, 0.6),
      rgb(41, 74, 71)
    );
    height: 34px;
    padding: 0 20px;
    .title_class {
      color: rgb(15, 246, 238);
      font-size: 18px;
      display: flex;
      align-items: center;
      font-weight: 600px;
      &::before {
        content: ' ';
        display: block;
        width: 4px;
        height: 18px;
        background-color: rgb(15, 246, 238);
        margin-right: 10px;
      }
    }
    .numAll {
      color: #36e2f7;
      font-weight: 500;
    }
  }

  // .numAll {
  //   position: absolute;
  //   right: 10px;
  //   top: 10px;
  //   color: #36e2f7;
  //   font-weight: 500;
  // }

  .chart_box {
    height: calc(100% - 34px);
  }

  .selectBox {
    position: absolute;
    left: 50%;
    top: 50px;
    width: 100px;
    transform: translateX(-50%);
    font-size: 16px;
    text-align: center;
    color: #fff;
    border: none;
    outline: none;
    background: transparent;
  }
}
</style>
