<template>
  <div class="RightTwo">
    <div class="title_box">
      <div class="title_class">今日安检</div>
      <div class="numAll">总计: 89 户</div>
    </div>
    <div class="chart_box" ref="line_chart"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  props: {
    msg: String
  },
  data() {
    return {
      chartInstance: null,
      timerId: null,
      startValue: 0,
      endValue: 5,
      dataList: [
        {
          name: '家庭',
          value: 100
        },
        {
          name: '餐饮',
          value: 100
        },
        {
          name: '工厂食堂',
          value: 300
        },
        {
          name: '工厂供应',
          value: 100
        },
        {
          name: '工厂食堂',
          value: 300
        },
        {
          name: '工厂',
          value: 200
        },
        {
          name: '仓库',
          value: 100
        },
        {
          name: '仓库2',
          value: 330
        },
        {
          name: '工厂供应',
          value: 230
        },
        {
          name: '冲撞',
          value: 300
        },
        {
          name: '家庭',
          value: 200
        },
        {
          name: '餐饮',
          value: 100
        }
      ],
      option: {
        dataZoom: {
          show: false,
          startValue: this.startValue,
          endValue: this.endValue
        },
        title: {
          text: '今日安检用户数: 200户',
          show: true,
          left: 'center',
          top: 10,
          textStyle: {
            //文字颜色
            color: '#fff',
            //字体风格,'normal','italic','oblique'
            fontStyle: 'normal',
            //字体粗细 'normal','bold','bolder','lighter',100 | 200 | 300 | 400...
            fontWeight: 'bold',
            //字体系列
            fontFamily: 'sans-serif',
            //字体大小
            fontSize: 14
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          show: false,
          data: []
        },

        grid: {
          left: '3%',
          right: '4%',
          bottom: '8%',
          top: '22%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: [],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              // 坐标轴刻度标签相关配置
              color: '#ffffff',
              fontSize: 14
            },
            splitLine: {
              // 坐标轴在 grid 区域中的分隔线
              lineStyle: {
                // 分割线配置
                color: 'rgba(255,255,255,0.15)' // 分割线颜色
              }
            }
          }
        ],
        series: [
          {
            name: '户数',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              position: 'top',
              fontStyle: {
                fontSize: 12,
                width: 2
              }
            },
            smooth: true,
            symbolSize: 6,
            areaStyle: {},
            itemStyle: {
              borderWidth: 2,
              // 指明颜色渐变的方向
              // 指明不同百分比之下颜色的值
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                // 百分之0状态之下的颜色值
                {
                  offset: 0,
                  color: '#5052EE'
                },
                // 百分之100状态之下的颜色值
                {
                  offset: 1,
                  color: '#AB6EE5'
                }
              ])
            },

            data: [120, 132, 101, 134, 90, 230, 210]
          }
        ]
      }
    };
  },
  methods: {
    initChart() {
      this.chartInstance = echarts.init(this.$refs.line_chart);
      // this.chartInstance.setOption(this.option);
      this.chartInstance.on('mouseover', () => {
        clearInterval(this.timerId);
      });
      this.chartInstance.on('mouseout', () => {
        this.startInterval();
      });
    },
    // 更新图表
    updateChart() {
      const dataNames = this.dataList.map((item) => {
        return item.name;
      });

      const dataValues = this.dataList.map((item) => {
        return item.value;
      });

      this.option.xAxis[0].data = dataNames;
      this.option.series[0].data = dataValues;

      this.option.dataZoom = {
        show: false,
        startValue: this.startValue,
        endValue: this.endValue
      };

      this.chartInstance.setOption(this.option, false);
    },

    startInterval() {
      if (this.timerId) {
        clearInterval(this.timerId);
      }
      this.timerId = setInterval(() => {
        this.startValue++;
        this.endValue++;
        if (this.endValue > this.dataList.length - 1) {
          this.startValue = 0;
          this.endValue = 5;
        }
        this.updateChart();
      }, 2000);
    }
  },
  mounted() {
    this.initChart();
    this.updateChart();
    this.startInterval();
  }
};
</script>

<style lang="scss" scoped>
.RightTwo {
  // background: url('../assets/rightTwo.png');
  background-size: 100% 100%;
  width: 100%;
  height: 100%;

  position: relative;
  .title_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(
      to right,
      rgba(59, 247, 240, 0.6),
      rgb(41, 74, 71)
    );
    height: 34px;
    padding: 0 20px;
    .title_class {
      color: rgb(15, 246, 238);
      font-size: 18px;
      display: flex;
      align-items: center;
      font-weight: 600px;
      &::before {
        content: ' ';
        display: block;
        width: 4px;
        height: 18px;
        background-color: rgb(15, 246, 238);
        margin-right: 10px;
      }
    }
    .numAll {
      color: #36e2f7;
      font-weight: 500;
    }
  }

  .chart_box {
    height: calc(100% - 34px);
  }
}
</style>
