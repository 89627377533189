<template>
  <div class="bg-color">
    <AutoScreen>
      <div class="header_box">
        <span>可视化数据大屏</span>
      </div>
      <div class="charts_container">
        <div class="left_module model_item">
          <div class="one chart_item">
            <LeftOne></LeftOne>
          </div>
          <div class="two chart_item">
            <LeftTwo></LeftTwo>
          </div>
          <div class="three chart_item">
            <LeftThree></LeftThree>
          </div>
        </div>
        <div class="main_module">
          <div class="one">
            <CenterOne></CenterOne>
          </div>
          <div class="two">
            <CenterTwo></CenterTwo>
          </div>
        </div>
        <div class="right_module model_item">
          <div class="one chart_item">
            <RightOne></RightOne>
          </div>
          <div class="two chart_item">
            <RightTwo></RightTwo>
          </div>
          <div class="three chart_item">
            <RightThree></RightThree>
          </div>
        </div>
      </div>
    </AutoScreen>
  </div>
</template>
<script>
import AutoScreen from '@/components/AutoScreen';
import LeftOne from '@/views/bigScreen/modules/LeftOne.vue';
import LeftTwo from '@/views/bigScreen/modules/LeftTwo.vue';
import LeftThree from '@/views/bigScreen/modules/LeftThree.vue';
import CenterOne from '@/views/bigScreen/modules/CenterOne.vue';
import CenterTwo from '@/views/bigScreen/modules/CenterTwo.vue';
import RightOne from '@/views/bigScreen/modules/RightOne.vue';
import RightTwo from '@/views/bigScreen/modules/RightTwo.vue';
import RightThree from '@/views/bigScreen/modules/RightThree.vue';

export default {
  name: 'BigScreen',
  props: {
    msg: String
  },
  components: {
    AutoScreen,
    LeftOne,
    LeftTwo,
    LeftThree,
    CenterOne,
    CenterTwo,
    RightOne,
    RightTwo,
    RightThree
  },
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
.bg-color {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  box-sizing: border-box;
}

.header_box {
  height: 100px;
  display: flex;
  align-items: center;
  font-size: 30px;
  color: #fff;
  justify-content: center;
  letter-spacing: 10px;
}

.charts_container {
  display: flex;
  justify-content: space-between;
  height: calc(100% - 100px);
  // height: 100%;
  .model_item {
    height: 100%;
    flex: 2.5;
    display: flex;
    flex-direction: column;

    // background-color: pink;

    .chart_item {
      width: 100%;
      // height: 290px;
      flex: 1;
    }
  }

  .main_module {
    flex: 5;
    // background-color: orchid;
    margin: 0 20px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .one {
      height: calc(100% - 300px);
    }

    .two {
      height: 300px;
    }
  }
}
</style>
