<template>
  <div class="content">
    <div class="topTitle">
      <span class="one">序号</span>
      <span class="two">姓名</span>
      <span class="three">地址</span>
      <span class="four">购买时间</span>
    </div>
    <vue-seamless-scroll
      :data="dataList"
      :class-option="classOption"
      class="list"
    >
      <div class="item" v-for="(v, i) in dataList" :key="i">
        <span class="one">{{ i + 1 }}</span>
        <span class="two">{{ v.name }}</span>
        <span class="three">{{ v.address }}</span>
        <span class="four">{{ v.time }}</span>
      </div>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll';
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  comments: {
    vueSeamlessScroll
  },
  computed: {
    classOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        // limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      };
    }
  },
  data() {
    return {
      dataList: [
        {
          id: 1,
          name: '章三',
          address: '南京市建业区油坊桥',
          time: '2023-11-12 12:11:30'
        }
      ]
    };
  },
  mounted() {
    for (let i = 0; i < 20; i++) {
      let dataItem = {
        id: new Date().getTime(),
        name: '章三',
        address: '南京市建业区油坊桥',
        time: '2023-11-12 12:11:30'
      };
      this.dataList.push(dataItem);
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  background-size: 100% 100%;
  width: 100%;
  height: 300px;
}
.select {
  position: absolute;
  right: 10px;
  top: 6px;
}
.topTitle {
  // display: grid;
  // grid-template-columns: 1fr 2fr 3fr 3fr;
  justify-items: center;
  align-items: center;
  display: flex;
  height: 34px;
  background: #1a3452;
  font-size: 14px;
  color: #009ef7;
  .one {
    flex: 1;
  }
  .two {
    flex: 2;
  }
  .three {
    flex: 3;
  }
  .four {
    flex: 3;
  }
}
.list {
  height: calc(100% - 34px);
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .item:nth-child(even) {
    background: linear-gradient(
      90deg,
      rgba(73, 147, 243, 0) 0%,
      rgba(105, 144, 227, 0.5),
      rgba(6, 47, 100, 0) 100%
    );
  }
  .item {
    // display: grid;
    // grid-template-columns: 1fr 2fr 3fr 3fr;
    // justify-items: center;
    // align-items: center;
    // color: #fff;
    // font-size: 10px;
    // height: 34px;
    // span {
    //   text-align: center;
    // }
    display: flex;
    justify-items: center;
    align-items: center;
    height: 34px;
    font-size: 10px;
    color: #fff;
    .one {
      flex: 1;
    }
    .two {
      flex: 2;
    }
    .three {
      flex: 3;
    }
    .four {
      flex: 3;
    }
  }
}
</style>
