<template>
  <div class="RightOne">
    <div class="title_box">
      <div class="title_class">异常情况</div>
      <div class="numAll">总计: 100 户</div>
    </div>
    <div class="chart_box" ref="bar_chart1"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  props: {
    msg: String
  },
  data() {
    return {
      chartInstance: null,
      timerId: null,
      startValue: 0,
      endValue: 5,
      dataList: [
        {
          name: '家庭',
          value: 100
        },
        {
          name: '工厂饭堂',
          value: 100
        },
        {
          name: '餐饮',
          value: 300
        },
        {
          name: '工厂供应',
          value: 200
        },
        {
          name: '工厂工业',
          value: 100
        },
        {
          name: '工厂饭堂',
          value: 330
        },
        {
          name: '家庭',
          value: 230
        },
        {
          name: '餐饮',
          value: 300
        }
      ],
      option: {
        title: {
          text: '待检户数: 27户',
          show: true,
          left: 'center',
          top: 10,
          textStyle: {
            //文字颜色
            color: '#fff',
            //字体风格,'normal','italic','oblique'
            fontStyle: 'normal',
            //字体粗细 'normal','bold','bolder','lighter',100 | 200 | 300 | 400...
            fontWeight: 'bold',
            //字体系列
            fontFamily: 'sans-serif',
            //字体大小
            fontSize: 14
          }
        },
        dataZoom: {
          show: false,
          startValue: this.startValue,
          endValue: this.endValue
        },
        grid: {
          left: '3%',
          right: '5%',
          top: '20%',
          bottom: '8%',
          containLabel: true
        },
        tooltip: {
          trigger: 'item',
          formatter: function (parms) {
            return parms.marker + ' ' + parms.name + '：' + parms.value + '户';
          }
        },
        xAxis: {
          type: 'category', // category(坐标轴类型)
          data: [],
          axisTick: {
            // 坐标轴刻度相关配置
            show: false // 是否显示坐标轴刻度
          },
          axisLine: {
            // 坐标轴轴线相关配置
            lineStyle: {
              // 坐标轴轴线样式
              color: 'rgba(255,255,255,0.15)' // 坐标轴轴线颜色
            }
          },
          axisLabel: {
            // 坐标轴刻度标签相关配置
            color: '#ffffff',
            fontSize: 14,
            margin: 20
          }
        },
        yAxis: {
          type: 'value', // value(数值轴,适用于连续数据)
          axisTick: {
            // 坐标轴刻度相关配置
            show: false // 是否显示坐标轴刻度
          },
          axisLine: {
            // 坐标轴轴线相关配置
            show: false // 是否显示坐标轴轴线
          },
          axisLabel: {
            // 坐标轴刻度标签相关配置
            color: '#ffffff',
            fontSize: 14
          },
          splitLine: {
            // 坐标轴在 grid 区域中的分隔线
            lineStyle: {
              // 分割线配置
              color: 'rgba(255,255,255,0.15)' // 分割线颜色
            }
          }
        },
        series: [
          // 底部的椭圆形(象形柱图):pictorialBar
          {
            type: 'pictorialBar', // pictorialBar(象形柱图)
            label: {
              // 图形上的文本标签,可用于说明图像的一些数据信息,比如值,名称等
              show: true, //是否显示标签
              position: ['15', '-28'], // 标签的位置(可以是绝对的像素值或者百分比['50%','50%',也可以是top,left等])
              color: '#01E4FF',
              fontSize: 14
            },
            symbolSize: [40, 20], // 图形的大小用数组分别比表示宽和高,也乐意设置成10相当于[10,10]
            symbolOffset: [0, 10], // 图形相对于原本位置的偏移
            z: 12, // 象形柱状图组件的所有图形的 z 值.控制图形的前后顺序.z 值小的图形会被 z 值大的图形覆盖.
            itemStyle: {
              // 图形样式
              // echarts.graphic.LinearGradient(echarts内置的渐变色生成器)
              // 4个参数用于配置渐变色的起止位置,这4个参数依次对应右 下 左 上
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                // 这里 offset: 0 1 ,表示从下往上的渐变色
                {
                  offset: 0, // 0%处的颜色
                  color: 'rgba(254, 33, 30, 0.5)'
                },
                {
                  offset: 1, // 100%处的颜色
                  color: 'rgba(254, 33, 30, 1)'
                }
              ])
            },
            data: []
          },
          // 中间的长方形柱状图(柱状图):bar
          {
            type: 'bar', // 柱状图
            barWidth: 40, // 柱条的宽度,不设时自适应
            barGap: '0%', // 柱子与柱子之间的距离
            itemStyle: {
              // 图形样式
              // color支持(rgb(255,255,255)、rgba(255,255,255,1)、#fff,也支持渐变色和纹理填充)
              // 下面就是使用线性渐变
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                type: 'linear',
                global: false,
                colorStops: [
                  {
                    offset: 0, // 0%处的颜色
                    color: 'rgba(254, 33, 30, 0.5)'
                  },
                  {
                    offset: 1, // 100%处的颜色
                    color: 'rgba(254, 33, 30, 1)'
                  }
                ]
              }
            },
            data: []
          },
          // 顶部的椭圆形(象形柱图):pictorialBar
          {
            type: 'pictorialBar',
            symbolSize: [40, 20],
            symbolOffset: [0, -10],
            z: 12,
            symbolPosition: 'end',
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: 'rgba(254, 33, 30, 1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(254, 33, 30, 1)'
                  }
                ],
                false
              )
            },
            data: []
          }
        ]
      }
    };
  },

  methods: {
    initChart() {
      this.chartInstance = echarts.init(this.$refs.bar_chart1);
      this.chartInstance.setOption(this.option);
      this.chartInstance.on('mouseover', () => {
        clearInterval(this.timerId);
      });
      this.chartInstance.on('mouseout', () => {
        this.startInterval();
      });
    },
    // 更新图表
    updateChart() {
      const dataNames = this.dataList.map((item) => {
        return item.name;
      });

      const dataValues = this.dataList.map((item) => {
        return item.value;
      });

      this.option.xAxis.data = dataNames;
      this.option.series[0].data = dataValues;
      this.option.series[1].data = dataValues;
      this.option.series[2].data = dataValues;
      this.option.dataZoom = {
        show: false,
        startValue: this.startValue,
        endValue: this.endValue
      };

      this.chartInstance.setOption(this.option);
    },

    startInterval() {
      if (this.timerId) {
        clearInterval(this.timerId);
      }
      this.timerId = setInterval(() => {
        this.startValue++;
        this.endValue++;
        if (this.endValue > this.dataList.length - 1) {
          this.startValue = 0;
          this.endValue = 5;
        }
        this.updateChart();
      }, 2000);
    }
  },
  mounted() {
    this.initChart();
    this.updateChart();
    this.startInterval();
  }
};
</script>

<style lang="scss" scoped>
.RightOne {
  // background: url('../assets/rightOne.png');
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  // display: flex;
  // align-items: flex-end;
  position: relative;
  .title_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(
      to right,
      rgba(59, 247, 240, 0.6),
      rgb(41, 74, 71)
    );
    height: 34px;
    padding: 0 20px;
    .title_class {
      color: rgb(15, 246, 238);
      font-size: 18px;
      display: flex;
      align-items: center;
      font-weight: 600px;
      &::before {
        content: ' ';
        display: block;
        width: 4px;
        height: 18px;
        background-color: rgb(15, 246, 238);
        margin-right: 10px;
      }
    }
    .numAll {
      color: #36e2f7;
      font-weight: 500;
    }
  }

  .chart_box {
    height: calc(100% - 34px);
  }
}
</style>
