<template>
  <div class="RightThree">
    <div class="title_box">
      <div class="title_class">有效用户</div>
      <div class="numAll">总计: 2000 户</div>
    </div>
    <div class="chart_box" ref="bar_chart"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

const colors = [
  [
    { offset: 0, color: '#1F9BFF' },
    { offset: 1, color: 'rgba(52, 163, 224, 0.4)' }
  ],
  [
    { offset: 0, color: 'rgba(137, 163, 164, 1)' },
    { offset: 1, color: 'rgba(137, 163, 164, 0.3)' }
  ],
  [
    { offset: 0, color: 'rgba(44, 166, 166, 1)' },
    { offset: 1, color: 'rgba(44, 166, 166, 0.3)' }
  ],
  [
    { offset: 0, color: 'rgba(34, 66, 186, 1)' },
    { offset: 1, color: 'rgba(34, 66, 186, 0.3)' }
  ]
];

// 定义柱状图左侧图形元素
const leftRect = echarts.graphic.extendShape({
  shape: {
    x: 0,
    y: 0,
    width: 19, //柱状图宽
    zWidth: 8, //阴影折角宽
    zHeight: 4 //阴影折角高
  },
  buildPath: function (ctx, shape) {
    const api = shape.api;
    const xAxisPoint = api.coord([shape.xValue, 0]);
    const p0 = [shape.x - shape.width / 2, shape.y - shape.zHeight];
    const p1 = [shape.x - shape.width / 2, shape.y - shape.zHeight];
    const p2 = [xAxisPoint[0] - shape.width / 2, xAxisPoint[1]];
    const p3 = [xAxisPoint[0] + shape.width / 2, xAxisPoint[1]];
    const p4 = [shape.x + shape.width / 2, shape.y];

    ctx.moveTo(p0[0], p0[1]);
    ctx.lineTo(p1[0], p1[1]);
    ctx.lineTo(p2[0], p2[1]);
    ctx.lineTo(p3[0], p3[1]);
    ctx.lineTo(p4[0], p4[1]);
    ctx.lineTo(p0[0], p0[1]);
    ctx.closePath();
  }
});
// 定义柱状图右侧以及顶部图形元素
const rightRect = echarts.graphic.extendShape({
  shape: {
    x: 0,
    y: 0,
    width: 18,
    zWidth: 15,
    zHeight: 8
  },
  buildPath: function (ctx, shape) {
    const api = shape.api;
    const xAxisPoint = api.coord([shape.xValue, 0]);
    const p1 = [shape.x - shape.width / 2, shape.y - shape.zHeight / 2];
    const p3 = [xAxisPoint[0] + shape.width / 2, xAxisPoint[1]];
    const p4 = [shape.x + shape.width / 2, shape.y];
    const p5 = [xAxisPoint[0] + shape.width / 2 + shape.zWidth, xAxisPoint[1]];
    const p6 = [
      shape.x + shape.width / 2 + shape.zWidth,
      shape.y - shape.zHeight / 2
    ];
    const p7 = [
      shape.x - shape.width / 2 + shape.zWidth,
      shape.y - shape.zHeight
    ];
    ctx.moveTo(p4[0], p4[1]);
    ctx.lineTo(p3[0], p3[1]);
    ctx.lineTo(p5[0], p5[1]);
    ctx.lineTo(p6[0], p6[1]);
    ctx.lineTo(p4[0], p4[1]);

    ctx.moveTo(p4[0], p4[1]);
    ctx.lineTo(p6[0], p6[1]);
    ctx.lineTo(p7[0], p7[1]);
    ctx.lineTo(p1[0], p1[1]);
    ctx.lineTo(p4[0], p4[1]);
    ctx.closePath();
  }
});

export default {
  name: 'LeftOneChart',
  props: {
    msg: String
  },
  data() {
    return {
      chartInstance: null,
      timerId: null,
      startValue: 0,
      endValue: 4,
      stepLength: 4,
      dataZoom: {
        show: false,
        startValue: this.startValue,
        endValue: this.endValue
      },
      dataList: [
        {
          name: '家庭',
          value: 100
        },
        {
          name: '餐饮',
          value: 100
        },
        {
          name: '工厂饭堂',
          value: 300
        },
        {
          name: '工厂工业',
          value: 200
        },
        {
          name: '餐饮',
          value: 100
        },
        {
          name: '家庭',
          value: 330
        },
        {
          name: '工厂饭堂',
          value: 230
        }
      ],
      option: {
        tooltip: {
          trigger: 'item',
          formatter: function (parms) {
            return parms.marker + ' ' + parms.name + '：' + parms.value + '户';
          }
        },
        legend: {
          show: false,
          data: [],
          right: '25',
          top: '0',
          icon: 'rect',
          itemHeight: 10,
          itemWidth: 10,
          textStyle: {
            color: '#ffffff',
            fontSize: 14
          }
        },
        grid: {
          left: '5%',
          right: '5%',
          top: '20%',
          bottom: '0%',
          containLabel: true
        },

        xAxis: {
          axisTick: {
            show: false
          },
          nameTextStyle: {
            color: '#fff'
          },
          axisLine: {
            // 坐标轴轴线相关配置
            lineStyle: {
              // 坐标轴轴线样式
              color: 'rgba(255,255,255,0.15)' // 坐标轴轴线颜色
            }
          },
          axisLabel: {
            // 坐标轴刻度标签相关配置
            color: '#ffffff',
            fontSize: 14,
            margin: 20
          }
        },

        yAxis: {
          type: 'value',
          axisLabel: {
            // 坐标轴刻度标签相关配置
            color: '#ffffff',
            fontSize: 14
          },
          splitLine: {
            // 坐标轴在 grid 区域中的分隔线
            lineStyle: {
              // 分割线配置
              color: 'rgba(255,255,255,0.15)' // 分割线颜色
            }
          }
        },
        series: []
      }
    };
  },
  methods: {
    initChart() {
      echarts.graphic.registerShape('leftRect', leftRect);
      echarts.graphic.registerShape('rightRect', rightRect);
      this.chartInstance = echarts.init(this.$refs.bar_chart);
      this.chartInstance.on('mouseover', () => {
        clearInterval(this.timerId);
      });
      this.chartInstance.on('mouseout', () => {
        this.startInterval();
      });
    },
    // 更新图表
    updateChart() {
      const dataNames = this.dataList.map((item) => {
        return item.name;
      });
      const dataValues = this.dataList.map((item) => {
        return item.value;
      });

      //获取数据
      this.option.xAxis.data = dataNames;
      this.option.legend.data = this.getlegendData(dataNames);
      this.option.series = this.getSeriesData(dataValues);

      this.option.dataZoom = {
        show: false,
        startValue: this.startValue,
        endValue: this.endValue
      };

      //重新设置图表
      this.chartInstance.setOption(this.option);
    },

    startInterval() {
      if (this.timerId) {
        clearInterval(this.timerId);
      }
      this.timerId = setInterval(() => {
        this.startValue++;
        this.endValue++;
        if (this.endValue > this.dataList.length - 1) {
          this.startValue = 0;
          this.endValue = this.stepLength;
        }
        this.updateChart();
      }, 2000);
    },

    getlegendData(dataNames = []) {
      const data = [];
      dataNames.forEach((item, index) => {
        data.push({
          name: item,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(
              1,
              0,
              0,
              0,
              colors[index % 3]
            )
          }
        });
      });
      return data;
    },

    getSeriesData(dataValues) {
      const data = [];

      this.dataList.forEach((item, index) => {
        data.push({
          type: 'custom',
          name: item.name,
          renderItem: (params, api) => {
            return this.getRenderItem(params, api);
          },
          data: [item.value],
          itemStyle: {
            color: () => {
              return new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                colors[index % 3]
              );
            }
          }
        });
      });

      const data1 = [
        {
          type: 'custom',
          name: 'bar',
          renderItem: (params, api) => {
            return this.getRenderItem(params, api);
          },
          data: dataValues,
          label: {
            // 图形上的文本标签,可用于说明图像的一些数据信息,比如值,名称等
            show: true, //是否显示标签
            position: ['15', '-28'], // 标签的位置(可以是绝对的像素值或者百分比['50%','50%',也可以是top,left等])
            color: '#01E4FF',
            fontSize: 14
          },
          itemStyle: {
            color: (datas) => {
              return new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                colors[datas.dataIndex % 3]
              );
            }
          }
        }
      ];
      return data1;
    },

    getRenderItem(params, api) {
      const index = params.seriesIndex;
      let location = api.coord([api.value(0) + index, api.value(1)]);
      var extent = api.size([0, api.value(1)]);
      return {
        type: 'group',
        children: [
          {
            type: 'leftRect',
            shape: {
              api,
              xValue: api.value(0) + index,
              yValue: api.value(1),
              x: location[0],
              y: location[1]
            },
            style: api.style()
          },
          {
            type: 'rightRect',
            shape: {
              api,
              xValue: api.value(0) + index,
              yValue: api.value(1),
              x: location[0],
              y: location[1]
            },
            style: api.style()
          }
        ]
      };
    }
  },
  mounted() {
    this.initChart();
    this.updateChart();
    this.startInterval();
  }
};
</script>

<style lang="scss" scoped>
.RightThree {
  // background: url('../assets/rightThree.png');
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  position: relative;
  .title_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(
      to right,
      rgba(59, 247, 240, 0.6),
      rgb(41, 74, 71)
    );
    height: 34px;
    padding: 0 20px;
    .title_class {
      color: rgb(15, 246, 238);
      font-size: 18px;
      display: flex;
      align-items: center;
      font-weight: 600px;
      &::before {
        content: ' ';
        display: block;
        width: 4px;
        height: 18px;
        background-color: rgb(15, 246, 238);
        margin-right: 10px;
      }
    }
    .numAll {
      color: #36e2f7;
      font-weight: 500;
    }
  }

  .chart_box {
    height: calc(100% - 34px);
  }
}
</style>
