<template>
  <div class="CenterOne_container">
    <div class="header_nav">
      <template v-for="item in navOptionsList" ::key="item.type">
        <div class="nav_item">
          <div class="title_class">{{ item.title }}</div>
          <div class="num">{{ item.num }}</div>
        </div>
      </template>
    </div>
    <div class="main">
      <div class="chart_com" ref="map_ref"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
let shijie_map = require('../assets/map/shijie.json');
let mapPoint_json = require('../assets/data/mapPoint.json');

const colors = ['rgba(254, 33, 30, 1)', 'rgba(44, 166, 166, 1)', '#ccc'];
export default {
  name: 'CenterOne',
  props: {},
  data() {
    return {
      navOptionsList: [
        {
          title: '今日订单',
          type: '1',
          num: 20000
        },
        {
          title: '销售气瓶量',
          type: '2',
          num: 30000
        },
        {
          title: '已配送完成量',
          type: '3',
          num: 30000
        },
        {
          title: '待配送数量',
          type: '4',
          num: 30000
        }
      ],
      chartInstance: null,
      allData: null,
      option: {
        title: {
          show: false,
          text: '地图数据',
          left: 20,
          top: 20
        },
        tooltip: {
          trigger: 'item'
        },
        geo: {
          type: 'map',
          map: '石碣镇',
          top: '5%',
          bottom: '5%',
          itemStyle: {
            areaColor: '#2E72BF',
            borderColor: '#333'
          }
        },
        legend: {
          show: true,
          right: '5%',
          bottom: '5%',
          orient: 'vertical',
          textStyle: {
            //文字颜色
            color: '#fff',
            //字体风格,'normal','italic','oblique'
            fontStyle: 'normal',
            //字体粗细 'normal','bold','bolder','lighter',100 | 200 | 300 | 400...
            fontWeight: 'bold',
            //字体系列
            fontFamily: 'sans-serif',
            //字体大小
            fontSize: 14
          }
        },
        series: []
      }
    };
  },
  methods: {
    async initChart() {
      this.chartInstance = echarts.init(this.$refs.map_ref, this.theme);
      echarts.registerMap('石碣镇', shijie_map);
      this.chartInstance.setOption(this.option);
      this.chartInstance.on('click', async (arg) => {
        console.log('点击了');
      });
      //拿到数据了
      this.updateChart();
    },

    updateChart() {
      // 处理图表需要的数据
      const seriesArr =
        mapPoint_json &&
        mapPoint_json.map((item, index) => {
          // return的这个对象就代表的是一个类别下的所有散点数据
          // 如果想在地图中显示散点的数据, 我们需要给散点的图表增加一个配置, coordinateSystem:geo
          return {
            type: 'effectScatter',
            rippleEffect: {
              scale: 4,
              brushType: 'stroke'
            },

            coordinateSystem: 'bmap',

            symbolSize: [15, 15],
            itemStyle: {
              normal: {
                // 气泡颜色
                color: colors[index % 3]
              }
            },
            symbolOffset: [0, '-50%'],
            tooltip: {
              // 这个tooltip只有外面的tootip trriger item才会生效
              formatter: '{a} {b}'
            },
            name: item.name,
            data: item.children || [],
            coordinateSystem: 'geo'
          };
        });

      console.log(seriesArr, 'seriesArr');
      //重新设置
      this.option.series = seriesArr;
      this.chartInstance.setOption(this.option);
    }
  },
  mounted() {
    this.initChart();
  }
};
</script>

<style lang="scss" scoped>
.CenterOne_container {
  width: 100%;
  height: 100%;
  .header_nav {
    display: flex;
    justify-content: space-evenly;

    .nav_item {
      flex: 1;
      margin: 0 20px;
      background-color: rgba(79, 132, 246, 0.4);
      border: 1px solid rgba(52, 163, 224, 0.4);
      text-align: center;
      height: 80px;
      .title_class {
        font-size: 22px;
        color: rgba(79, 132, 246, 1);
        font-weight: 600;
      }
      .num {
        color: orange;
        margin: 5px 0 0 0;
        font-size: 30px;
      }
    }
  }

  .main {
    height: calc(100% - 40px);
    .chart_com {
      width: 100%;
      height: calc(100% - 40px);
    }
  }
}
</style>
